.footer-container {
  width: 100%;
  background-color: #0D0C04;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-link-items {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  /* padding: 5px;
 margin: 10px; */
  box-sizing: border-box;
}

.footer-link-item {
  box-sizing: border-box;
  padding: 10px;
}

.footer-link-item:hover {
  border-bottom: 1px solid #fff;
  transition: all 0.2s ease-in-out;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items>h2 {
  color: #ffffff;
}

.footer-link-items a {
  color: #ffffff;
  text-decoration: none;
  /* margin-bottom: 0.5rem; */
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #ffffff;
  font-size: 24px;
}

.social-icon-link:hover {
  border-bottom: 1px solid #fff;
  transition: all 0.2s ease-in-out;
}

.footer_container_items {
  display: flex;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 40px auto;
  /* max-width: 1000px; */
  /* margin: 40px auto 0 auto; */
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #ffffff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.social-logo:hover {
  border-bottom: 1px solid #fff;
  transition: all 0.1s ease-in-out;
}

.website-rights {
  color: #0D0C04;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {}