body {
  background-color: #ffffff;
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 30px;
  right: 0;
  left: 0;
  bottom: 30px;
  width: 100%;
  height: 100%;
}

.contact_header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 20px;
}

.contact_header:hover {
  color: rgba(0, 0, 0, 0.3);
}

.contact-container {
  margin-top: 1rem;
  font-family: "Cabinet-Grotesk", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  padding: 28px;
  background-color: #000000;
  position: relative;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 496px;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  border: none;
  color: #ffffff;
}

input {
  width: 100%;
  height: 35px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

input:focus {
  border: 2px solid rgba(0, 206, 158, 1);
}

textarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  max-height: 100px;
  min-height: 100px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

textarea:focus {
  border: 2px solid rgb(0, 45, 206);
}

label {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
}

input[type="submit"] {
  margin-top: 2rem;
  cursor: pointer;
  background: rgb(0, 0, 0);
  color: white;
  border: none;
}

.contact__button {
  width: 100%;
  height: 36px;
  padding: 8px, 16px, 8px, 16px;
  margin: 24px 0px;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 4px;
  color: white;
  font-family: "Cabinet-Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 700px;
  line-height: 20px;
  font-weight: bold;
}

.contact__button:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 960px) {
  .contact-container {
    width: 396px;
  }
}