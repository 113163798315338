/* video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
} */

.about-container {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
  background-color: #0D0C04;
}

.about__container-text>h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 60px;
  font-weight: 700;
  color: #D9D9D9;
  margin-left: 250px;
  margin-right: 250px;
  margin-top: 10px;
}

.about__container-text>p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: #D9D9D9;
  margin-left: 250px;
  margin-right: 250px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 996px) {
  .about__container-text>p {
    margin-left: 50px;
    margin-right: 50px;
    font-size: 20px;
  }

  .about__container-text>h1 {
    font-size: 30px;
    margin-left: 50px;
    margin-right: 50px;

  }

  .about__item-wrap {
    margin-top: 100px;
  }

  .about__item-info {
    font-size: 24px;
  }
}

@media screen and (max-width: 872px) {
  .about__container-text> {
    margin-left: auto;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .about__item-wrap {
    margin-top: 500px;
  }

  .about__item-info {
    font-size: 24px;
  }
}