* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}
/* 
.home,
.about,
.projects,
.contact {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.about {
  background-image: url("/public/images/img-home.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #db504a;
  font-size: 100px;
}

.projects {
  background-image: url("/public/images/img-home.jpg");
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #db504a;
  font-size: 100px;
}

.contact {
  background-image: url("/public/images/img-home.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #db504a;
  font-size: 100px;
} */
